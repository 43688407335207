import _ from 'lodash';

import { SubNode } from '@/components/org/subsidiaries/subNode';
import { SubPointer } from '@/components/org/subsidiaries/subPointer';

import { ApiSvcSubsidiary } from '../../generated/api-svc';

export class SubTree {
  static build(subs: ApiSvcSubsidiary[]) {
    const subTree = new Map<string, SubNode>();
    let root: SubNode | undefined;
    for (const s of subs) {
      let cur = subTree.get(s.id!);
      if (cur) {
        cur.sub = s;
      } else {
        cur = new SubNode(s.id!, s);
        subTree.set(s.id!, cur);
      }

      if (s.parentSubsidiaryId) {
        let curParent = subTree.get(s.parentSubsidiaryId);
        if (curParent === undefined) {
          curParent = new SubNode(s.parentSubsidiaryId, undefined, [cur]);
          subTree.set(s.parentSubsidiaryId, curParent);
        } else {
          curParent.subs.push(cur);
        }
      } else {
        root = cur;
      }
    }

    if (root === undefined) {
      throw new Error('Missing root sub');
    }
    // Recurse
    const flat = SubTree.flatten(root, 0);
    return flat;
  }

  static flatten(node: SubNode, offset: number): SubPointer[] {
    const ret: SubPointer[] = [{ id: node.sub!.id!, name: node.sub!.name, offset }];
    const orderedSubs = _.sortBy(node.subs, (m) => m.sub!.name);
    const subPointers = orderedSubs.flatMap((m) => SubTree.flatten(m, offset + 1));
    ret.push(...subPointers);
    return ret;
  }
}
