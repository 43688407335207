

























import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import NewSubsidiary from '@/components/org/subsidiaries/NewSubsidiary.vue';
import SubsidiariesList from '@/components/org/subsidiaries/SubsidiariesList.vue';
import UiButton from '@/components/ui/UiButton.vue';

import { baConfig } from '../../../config';
import { ApiSvcSubsidiary, OrganizationsApi } from '../../../generated/api-svc';
import { BaseVue } from '../../BaseVue';
@Component({
  components: {
    UiButton,
    NewSubsidiary,
    SubsidiariesList,
  },
})
export default class Subsidiaries extends BaseVue {
  public isCreating = false;
  public isLoading = false;

  public subs: ApiSvcSubsidiary[] = [];

  public mounted() {
    this.loadSubsidiaries();
  }

  public async createComplete(success: boolean) {
    this.isCreating = false;
    if (success) {
      await this.loadSubsidiaries();
    }
  }

  private async loadSubsidiaries() {
    this.isLoading = true;
    try {
      const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await orgsApi.listSubsidiaries(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.subs = resp.data;
      } else {
        throw new Error('Failed loading with status code: ' + resp.status);
      }
    } catch (e: any) {
      this.showErrorSnackbar('Problem loading subs: ' + e.message);
    } finally {
      this.isLoading = false;
    }
  }
}
