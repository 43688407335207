import { ApiSvcSubsidiary } from '../../../../generated/api-svc';

export class SubNode {
  constructor(id: string, sub?: ApiSvcSubsidiary, subs: SubNode[] = []) {
    this.id = id;
    this.sub = sub;
    this.subs = subs;
  }

  id: string;
  sub?: ApiSvcSubsidiary;
  // eslint-disable-next-line no-use-before-define
  subs: SubNode[];
}
