
































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

import { baConfig } from '../../../../config';
import {
  ApiSvcCreateSubsidiaryRequest,
  ApiSvcSubsidiary,
  ApiSvcSubsidiaryRelationship,
  OrganizationsApi,
} from '../../../../generated/api-svc';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    UiDataTable,
  },
})
export default class NewSubsidiary extends BaseVue {
  @Prop({ required: true })
  public subsidiaries!: ApiSvcSubsidiary[];

  public parentSubsidiary: string | null = null;

  public isCreatingSubsidiary = false;
  public isValidInput = false;

  public newSubsidiaryName = '';

  public get validateDataSourceName() {
    return this.newSubsidiaryName.length >= 3;
  }

  public get subs() {
    const r: Record<string, string> = {};
    for (const s of this.subsidiaries) {
      r[s.name] = s.id!;
    }
    return r;
  }

  public validate() {
    if (this.validateDataSourceName && this.parentSubsidiary) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  public async createSubsidiary() {
    if (this.isValidInput) {
      this.isCreatingSubsidiary = true;
      try {
        const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
        const sub: ApiSvcCreateSubsidiaryRequest = {
          parentSubsidiaryId: this.parentSubsidiary!,
          name: this.newSubsidiaryName!,
          relationship: ApiSvcSubsidiaryRelationship.Subsidiary,
          baseCurrency: 'USD',
        };
        const resp = await orgsApi.createSubsidiary(this.orgId, sub, { withCredentials: true });
        if (resp.status !== 200) {
          this.showErrorSnackbar('Problem creating sub: ' + resp.data);
        }
        this.$emit('back', true);
      } catch (e: any) {
        this.showErrorSnackbar('Problem creating sub: ' + e.message);
      } finally {
        this.isCreatingSubsidiary = false;
      }
    }
  }
  // public validate() {
  //   if (this.validateDataSourceName) {
  //     this.isValidInput = true;
  //   } else {
  //     this.isValidInput = false;
  //   }
  // }
}
